.modal-header {
  font-family: "Urbanist";
  font-weight: 600;
  padding: 2px;
  font-size: 20px;
}

.modal-label {
 
  font-weight: 500;

  font-size: 15px;
}

.ReactModal__Overlay {
  position: fixed;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  display: none;
}
.ReactModal__Content {
  display: none;
  inset: unset !important;
  background: rgba(255, 255, 255, 1) !important;
  position: relative !important;
  width: 350px;
  /* padding: 0 !important; */
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 4px 4px rgba(0, 0, 0, 0.133),
    0 3px 15px 0 rgba(0, 0, 0, 0.15);
  transform: translate(0) !important;
}
.ReactModal__Content .close-modal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0%;
  right: 0%;
  color: black;
  height: 30px;
  width: 30px;
  cursor: poUrbanist;
  font-weight: 600;
  text-transform: lowercase;
}
.ReactModal__Content h2 {
  font-size: 24px;
  font-weight: 600;
  color: #14478c;
  margin-bottom: 20px;
}
.ReactModal__Content p {
  font-size: 14px;
  font-weight: 500;
  color: #777;
}
.ReactModal__Content ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.ReactModal__Content ul li {
  width: 220px;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  column-gap: 20px;
}
.ReactModal__Content ul li .amount {
  justify-self: end;
  justify-self: flex-end;
  font-weight: 500;
  color: #14478c;
}
.ReactModal__Content ul li .divider {
  width: 20px;
  height: 1px;
  background-color: #c9c490;
}
.ReactModal__Content ul li .time {
  font-weight: 500;
  color: #14478c;
}
.ReactModal__Content a {
  display: block;
  margin: 20px auto 10px;
  width: max-content;

  background-color: #14478c;
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  text-decoration: none;
}
