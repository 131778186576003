

.navbar .menu{
    margin : 0px;
  
}

.navbar .menu li{
    list-style: none;
    display: inline-block;
    padding-top: 5px;
}
.navbar .menu li a{
    font-size: 18px;

font-weight: 500;
color: #193A66;

    transition: all 0.4s ease;
}

.navbar .menu li a:hover{

    transition: all 0.4s ease-out;
}


.navbar-light .navbar-nav .nav-link  :hover{
    transition: all 0.4s ease-out;
}


/* menu btn styling */
.menu-btn{
    color: #fff;
    font-size: 23px;
    cursor: poUrbanist;
    display: none;
}


@media (max-width: 980px){
    .menu-btn{
        display: block;
        z-index: 999;
        color:black;
        font-size:40px;
        cursor: poUrbanist;
        transition: all 0.4s ease;
      }

      
  .navbar .menu{
    position: fixed;
    height: 100vh;
    width: 100%;
    left: -100%;
    z-index: 999;
    top: 0;
    background:white;
    text-align: center;
    padding-top: 80px;
    padding-right: 50px;
    transition: all 0.3s ease;
}
.navbar .menu.active{
    left: 0;
}
.navbar .menu li{
    display: block;
}
.navbar .menu li a{
    display: inline-block;
    margin: 20px 0;
    font-size: 25px;
}

.navbar .menu li a:hover{
  color: var(--color);
  padding: 1rem 1rem;
  border-bottom: 4px solid var(--color);
  transition: all 0.3s ease-out;
  
}


}



@media (min-width: 980px){

    .featureAlign{
        transform: translateX(31px);
    }
}