.footer-text {
  text-align: left;
  margin-top: 20px;
  font-size: 17px;
  color: #144587;
  font-weight: 500;
}

.footer-text a {
  color: #144587;
}

.footer-ribbon {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 16px;
  text-align: "center";
  color: #010c18;
}

svg {
  margin-top: 15px;
}
