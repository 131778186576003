

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Urbanist Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: all 0.4s ease;
  font-family: 'Urbanist', sans-serif;
  letter-spacing: 0.2px;

}




html{
  scroll-behavior: smooth;

  transition: all 0.4s ease;
}
